<template>
  <div class="pagetot">
    <!-- 传过来的标题 -->
    <titlebar :name="name" />
    <div class="paflex">
      <!-- 左边区域 -->
      <div class="page_box">
        <div
          class="qd_left"
          v-for="(item, index) in list"
          :key="index"
          @click="selectTab(item, index)"
          :class="index == active ? 'modeact' : ''"
        >
          <div class="qd_num">{{ index + 1 }}号</div>
          <div class="qd_box">
            <div>{{ item.desc }}</div>
            <div>
              ￥<span class="qd_pri">{{ item.total_price }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 右边区域 -->
      <div class="qd_right">
        <!-- 标题 -->
        <div class="rg_title">
          <div class="tit1">商品名称</div>
          <div class="particular">
            <div class="tit2_gy">数量</div>
            <div class="tit3_gy">单位（元）</div>
            <div class="tit4_gy">小计（元）</div>
            <div class="tit5_gy">状态</div>
          </div>
        </div>
        <!-- 挂单列表 -->
        <div
          class="rg_list"
          v-for="(item, index) in takeInfo.info"
          :key="index"
        >
          <div class="list_num">{{ index + 1 }}</div>
          <div class="list_name">{{ item.name }}</div>
          <div class="tit2_gy">{{ item.num }}</div>
          <div class="tit3_gy">{{ item.price }}</div>
          <div class="tit4_gy">{{ item.total_price }}</div>
          <div class="tit5_gy">挂单</div>
        </div>
        <!-- 结算 -->
        <div class="btm_btn_total">
          <div class="bt_name">订单金额</div>
          <div class="bt_total">
            <!-- <div class="bt_ze">
              <div class="bt_sp">商品总额</div>
              <div class="bt_sum">￥<span>{{ takeInfo.total_price }}</span></div>
            </div> -->
            <div class="bt_yf">
              <div class="bt_text">商品总额：</div>
              <div class="bt_actual">
                ￥<span>{{ takeInfo.total_price }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 收银 -->
        <div class="btm_btn_box">
          <div class="flex">
            <div class="btm_sc" @click="openDialog()">删除</div>
            <!-- <div class="btm_sc">打印</div> -->
          </div>
          <div class="flex">
            <!-- <div class="btm_sc" @click="Addition()">追加</div> -->
            <div class="btm_sy" @click="Addition()">收银</div>
          </div>
        </div>
      </div>
    </div>

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      @closePopDialog="closePopDialog"
      @confirmPopDialog="confirmPopDialog"
    ></popupOfDialog>
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import popupOfDialog from "../component/popupOfDialog.vue";

export default {
  created() {
    this.name = this.$route.query.name || ""; //拿到传过来的标题
    console.log("name:", this.name);
    console.log(this.$route.query.index, "this.$route.query.index");
    this.active = this.$route.query.index || 0;
    this.getList();
  },
  components: {
    titlebar: titlebar,
    popupOfDialog,
  },
  data() {
    return {
      list: [],
      takeInfo: {
        info: [],
      }, // 挂单商品详情
      active: 0, // 左边列表下标
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      dialogType: "",
      dialogData: {},
    };
  },
  methods: {
    // 取单-挂单列表
    getList() {
      this.$api
        .hangList({
          // shop_id: localStorage.getItem('shop_id')
        })
        .then((res) => {
          this.list = res.data.reverse(); // 数据是相反的
          this.list.forEach((item) => {
            item.total_price = item.info.reduce((total, val) => {
              return total + Number(val.total_price);
            }, 0);
            item.total_price = item.total_price.toFixed(2);
          });
          if (res.data.length > 0) {
            this.takeInfo = this.active ? res.data[this.active] : res.data[0];
          } else {
            this.takeInfo = {
              info: [],
            };
          }
          console.log("挂单详情：", this.takeInfo);
          console.log(res, this.takeInfo, "res111");
        });
    },
    // 挂单切换
    selectTab(item, index) {
      this.active = index;
      console.log(this.active);
      this.takeInfo = Object.assign({}, item);
    },
    confirmPopDialog() {
      this.Delete();
      this.closePopDialog();
    },
    closePopDialog(data) {
      this.showDialog = false;
    },
    openDialog() {
      this.dialogTitle = "提示";
      this.dialogMsg = `是否确认删除？`;
      this.dialogType = '4';
      this.dialogData = {};
      this.showDialog = true;
    },
    // 删除挂单
    Delete() {
      this.$api.orderDelete({
        shop_id: localStorage.getItem("shop_id"),
        order_num: this.takeInfo.order_num,
      }).then((res) => {
        // const index = this.list.findIndex(e => {
        //   return e.order_num == this.takeInfo.order_num;
        // })
        // console.log(index);
        // this.list.splice(index, 1);
        this.active = 0;
        this.getList();
      });
    },
    // 跳转至结算界面
    // goTosettlement() {
    //   this.$store.commit('updateAreCacellProList', this.takeInfo.info);
    //   this.$store.commit('updateAreCancellOrderNum', this.takeInfo.order_num);
    //   this.$router.push({ path: "/settlement", query: { name: "settlement", type: 3 } });
    // },
    // 追加跳转首页
    Addition() {
      this.$store.commit("updateAreCancellOrderNum", this.takeInfo.order_num);
      // this.$store.commit('updateAreCacellProList', this.takeInfo.info);
      this.$store.commit("updateShopCart", []);
      this.$router.replace({ name: "index" });
    },
  },
};
</script>

<style lang="less" scoped>
.pagetot {
  width: 100%;
  height: 100%;
  background: #f8f8f8;

  .paflex {
    display: flex;
    height: 100%;
  }

  .page_box {
    width: 47.5rem;
    // height: 97.9rem;
    height: calc(100% - 9rem);
    background: #ffffff;
    overflow-x: hidden;
    overflow-y: scroll;

    .qd_left {
      width: 100%;
      height: 11.2rem;
      border-bottom: 1px solid #e5e5e5;
      font-size: 2.6rem;
      line-height: 3.7rem;
      background: #ffffff;
      // background: rgba(254, 93, 0, 0.06);

      .qd_num {
        text-align: left;
        font-weight: bold;
        padding: 1.1rem 1.5rem 0 3.5rem;
      }

      .qd_box {
        display: flex;
        justify-content: space-between;
        margin: 1.1rem 1.5rem 0 3.5rem;
        font-size: 2.4rem;
        font-weight: 400;

        .qd_pri {
          font-size: 2.6rem;
          font-weight: bold;
          line-height: 3.3rem;
        }
      }
      .qd_box > div:first-child {
        overflow: hidden; /*超出不显示*/
        text-overflow: ellipsis; /* 超出内容显示为省略号*/
        white-space: nowrap; /*文本不进行换行*/
        margin-right: 10px;
      }
    }
    .modeact {
      background: rgba(254, 93, 0, 0.06);
      color: #1588f5;
    }
  }

  .qd_right {
    width: calc(100% - 47.5rem);
    height: 98rem;

    .rg_title {
      width: 100%;
      height: 6rem;
      display: flex;
      // justify-content: center;
      align-items: center;
      // flex-wrap: wrap;
      font-size: 2.4rem;
      font-weight: 400;
      color: #333333;
      line-height: 3.3rem;

      .particular {
        display: flex;
      }

      .tit1 {
        width: 49.6rem;
        padding-left: 9.1rem;
        text-align: left;
      }
    }
  }

  .rg_list {
    width: 100%;
    height: 8.6rem;
    display: flex;
    align-items: center;
    font-size: 2.6rem;
    font-weight: 400;
    color: #333333;
    line-height: 3.7rem;
    background: #ffffff;
    border: 1px solid #e5e5e5;

    .list_num {
      width: 10rem;
    }

    .list_name {
      width: 49.6rem;
      text-align: left;
    }
  }

  .tit2_gy {
    width: 15rem;
  }
  .tit3_gy {
    width: 18rem;
  }
  .tit4_gy {
    width: 18rem;
  }
  .tit5_gy {
    width: 21rem;
  }

  .flex {
    display: flex;
  }
  // 订单结算
  .btm_btn_total {
    width: 124rem;
    height: 9.6rem;
    margin: 0 3rem 0 3rem;
    position: fixed;
    bottom: 15rem;
    right: 0.6rem;
    background: #ffffff;
    display: flex;
    border: 0.1rem solid #e5e5e5;

    .bt_name {
      width: 18.5rem;
      height: 100%;
      font-size: 2.6rem;
      font-weight: bold;
      color: #333333;
      line-height: 3.7rem;
      border-right: 0.1rem solid #e5e5e5;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .bt_total {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 4rem 0 5rem;

      .bt_ze {
        display: flex;
        align-items: center;

        .bt_sp {
          font-size: 2.4rem;
          font-weight: 500;
          color: #333333;
          line-height: 3.3rem;
          margin-right: 7.6rem;
        }

        .bt_sum {
          font-size: 2.4rem;
          font-weight: 500;
          color: #1588f5;
          line-height: 3.3rem;

          span {
            font-size: 2.6rem;
            font-weight: bold;
          }
        }
      }

      .bt_yf {
        display: flex;
        align-items: center;

        .bt_text {
          font-size: 2.6rem;
          font-weight: bold;
          color: #333333;
          line-height: 3.7rem;
        }

        .bt_actual {
          font-size: 2.4rem;
          font-weight: 500;
          color: #1588f5;
          line-height: 3.3rem;
          margin-left: 1.8rem;

          span {
            font-size: 3.2rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .btm_btn_box {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 123.1rem;
    height: 12.7rem;
    background: #ffffff;
    border: 0.1rem solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5.9rem 0 2.1rem;

    .btm_sc {
      width: 16.8rem;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2rem;
      border: 0.1rem solid #e5e5e5;
      font-size: 2.6rem;
      font-weight: 400;
      color: #1588f5;
      line-height: 3.7rem;
    }

    .btm_sy {
      width: 16.8rem;
      height: 6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 2rem;
      border: 0.1rem solid #e5e5e5;
      font-size: 2.6rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 3.7rem;
      background: #1588f5;
    }
  }
}
</style>
